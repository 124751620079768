/* eslint-disable */
import { defineStore } from 'pinia';
import { applyDrag, generateItems } from '../worker_order/services/helpers';
import moment from 'moment';
import apiWarehouse from '@/warehouse/services/WarehouseService';
import apiOtsService from '@/worker_order/services/OtsService';
import apiOtsWarehouseService from '@/worker_order/services/OtsWarehouseService';

let formOrderWork = {
  coupled_id: '',
  has_warehouse: true,
  applicant: '',
  id: '',
  signature_image: null,
  truck_id: '',
  applicant: '',
  responsible: '',
  kilometer: '',
  request: '',
  description_of_requeriment: '',
  path_image_signature: '',
  date_estimed: '',
  hour_estimed: '',
  place: '',
  type_of_maintenance: '',
  status: 'PENDING',
  codes: [],
  review_by: '',
  owner_user_id: '',
  applicant_driver_id: '',
  responsible_specialist_id: '',
  reviewed_by_specialist_id: '',
  delivered_to_driver_id: '',
  delivered_to: '',
  observation: '',
  works: [],
  deadline_at: '',
  defaultWork: {
    id: "",
    description: "",
    reviewed_by_specialist_id: "",
    hours: '',
    task: '',
    specialist: "",
    target_start_date: "",
    target_start_time: "",
    target_end_date: "",
    target_end_time: "",
    start_time: "",
    end_time: "",
    duration: "",
    duration_time: "",
    duration_hour: "",
  },
  editedIndexWork: -1,
  editedWork: {
    id: "",
    specialist: "",
    reviewed_by_specialist_id: "",
    description: "",
    hours: '',
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    target_start_date: "",
    target_start_time: "",
    target_end_date: "",
    target_end_time: "",
  },
  supplies: [],
  supplies_with_warehouse: [],
  defaulSupplies: {
    id: '',
    code: '',
    name: '',
    description: '',
    amount: '',
    cost: null,
  },
  editedIndexSupplies: -1,
  editedSupplies: {
    id: '',
    code: '',
    name: '',
    description: '',
    amount: '',
    cost: null,
  },
}

export const useAppStore = defineStore('app', {
  state: () => ({
    loadingCalendar: false,
    groupsCalendar: [],
    selectedEventType: ['Ordenes de Trabajo'],
    events: [],
    worksCalendars : [],
    otsCalendars: [],
    specialistsWithoutFormat: [],
    selectedSpecialistCalendar: null,
    filtersCalendar: {
      start: '',
      end: '',
      specialist: [],
      box_id: '',
    },
    ots: [],
    settings: null,
    preferences: null,
    preferences_user: [],
    codes: [],
    params: {
      page: 1,
      perPage: 5,
      search: '',
    },
    sideBarCreateAlertOpen: false,
    generalDrawer: false,
    generalDialog: false,
    loadingSupplies: false,
    drawer_service: false,
    drawer_filter: false,
    drawer_filter_content: '',
    patents: [],
    originalObjectCreateWork: {},
    order_is_edited: false,
    drawer: false,
    sheet_create_modal: false,
    drawer_create_order: false,
    drawer_supplies: false,
    drawer_supplies_with_warehouse: false,
    trucks: [],
    users: [],
    specialists: [],
    drivers: [],
    locations: [],
    company: null,
    role: null,
    workshop: null,
    drawer_filter_history_order_work: false,
    filter_history_order_work: {
      from: '',
      to: '',
      truck: '',
      location: '',
      responsable: '',
      applicant: '', // "SOLICITANTE"
      specialists: '', // "ENCARGADO"
      review_by: '', // "REVISADO POR"
      delivered_to: '', // "ENTREGADO A"
      search: '',
      status: '',
    },
    error_form_work_order: {},
    ots_by_groups: {
      pending: [],
      in_revision: [],
      in_progress: [],
      finalized: [],
    },
    snackbar: {
      visible: false,
      text: '',
    },
    coupled: [],
    counterFilterHistory: 0,
    supplies: [],
    currentWarehouse: null,
    error_supplies: [],
    formTask: {
      code: '',
      name: '',
    },
    form_create_work: {
      box_id: null,
      coupled_id: '',
      has_warehouse: false,
      workshop_selected_supply: false,
      warehouse_id: '',
      workshop_id: '',
      applicant: '',
      id: '',
      img_signature_delivered: '',
      img_signature_reviewed: '',
      is_signature_delivered: false,
      is_signature_reviewed: false,
      truck_id: '',
      applicant: '',
      responsible: '',
      kilometer: '',
      request: '',
      description_of_requeriment: '',
      path_image_signature: '',
      date_estimed: '',
      hour_estimed: '',
      place: '',
      type_of_maintenance: '',
      status: 'PENDING',
      codes: [],
      review_by: '',
      owner_user_id: '',
      applicant_driver_id: '',
      responsible_specialist_id: '',
      responsible_specialist_search: '',
      responsible_specialist_loading: false,
      reviewed_by_specialist_id: '',
      delivered_to_driver_id: '',
      delivered_to: '',
      observation: '',
      works: [],
      deadline_at: '',
      defaultWork: {
        id: "",
        description: "",
        reviewed_by_specialist_id: "",
        hours: '',
        task: '',
        specialist: "",
        target_start_date: "",
        target_start_time: "",
        target_end_date: "",
        target_end_time: "",
        start_time: "",
        end_time: "",
        duration: "",
        duration_time: "",
        duration_hour: "",
      },
      editedIndexWork: -1,
      editedWork: {
        id: "",
        specialist: "",
        reviewed_by_specialist_id: "",
        description: "",
        hours: '',
        task: '',
        target_start_date: "",
        target_start_time: "",
        target_end_date: "",
        target_end_time: "",
        start_time: "",
        end_time: "",
        duration: "",
        duration_time: "",
        duration_hours: "",
      },
      supplies: [],
      supplies_with_warehouse: [],
      defaulSupplies: {
        id: '',
        code: '',
        name: '',
        description: '',
        amount: '',
        cost: null,
      },
      editedIndexSupplies: -1,
      editedSupplies: {
        id: '',
        code: '',
        name: '',
        description: '',
        amount: '',
        cost: null,
      },
      workshop_entry_date: "",
      workshop_entry_time: "",
      workshop_departure_date: "",
      workshop_departure_time: "",
      services: [
      ]
    },
    from_filter: '',
    progress_loader: false,
    linear_progress_loader: false,
    currentForm: null,
    currentFormDialog: null,
    warehouses: [],
    countTotalHistoryOrderWorks: 0,
    boxes: [],
  }),
  mutations: {
    setDrawerSupplies(state, payload) {
      state.drawer_supplies = payload;
    },
  },
  actions: {
    async handleSpecialists(params) {
      try {
        const response = await apiOtsService.getSpecialists(params);

        this.specialists = response.data.map((e) => {
          return {
            ...e,
            fullTextWithoutDni: e.dni + ' - ' + e.names + ' ' + e.last_name_p + ' ' + e.last_name_m,
            fullText:
              e.names +
              ' ' +
              e.last_name_p +
              ' ' +
              e.last_name_m +
              ' ' +
              e.dni +
              ' ' +
              e.position.name,
          };
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      // handle the response data here
    },
    async getWarehousesByLocation(workshop) {
      const response = await apiWarehouse.getWarehousesByLocation(workshop);
      this.warehouses = response.data;
    },
    async fetchTrucks() {
      const response = await apiOtsService.getTrucks();
      this.trucks = response.data;
    },
    async fetchRequest() {
      const response = await apiOtsService.getUsers();
      this.users = response.data;
    },
    async fetchLocations() {
      const response = await apiOtsService.getLocations();
      this.locations = response.data;
    },
    async fetchSpecialists() {
      const response = await apiOtsService.getSpecialists();
      this.specialists = response.data.map((e) => {
        return {
          ...e,
          fullTextWithoutDni: e.dni + ' - ' + e.names + ' ' + e.last_name_p + ' ' + e.last_name_m,
          fullText:
            e.names +
            " " +
            e.last_name_p +
            " " +
            e.last_name_m +
            " " +
            e.dni +
            " " +
            e.position.name,
        };
      });
    },
    async fetchCoupleds() {
      const response = await apiOtsService.getCoupled();
      this.coupled = response.data;
    },
    async fetchLocations() {
      const response = await apiOtsService.getLocations();
      this.locations = response.data;
    },
    async fetchWarehouse() {
      const response = await apiWarehouse.getWarehouses();
      this.warehouses = response.data;
    },
    toogleGeneralDrawer(val, form) {
      this.generalDrawer = val;
      this.currentFormDialog = form;
    },
    toogleGeneralDialog(val, form) {
      this.currentFormDialog = form;
      this.generalDialog = val;
    },
    toggleDrawerFilterRight(from) {
      this.drawer_filter = !this.drawer_filter;
      this.drawer_filter_content = null;
    },
    defaultFormOrderWork() {
      return JSON.parse(
        JSON.stringify({
          ...formOrderWork,
        })
      );
    },
    async deleteWork(work_id) {
      await apiOtsService.deleteWork(work_id);
    },
    setInitialLocation(val) {
      this.filter_history_order_work.location = val;
    },
    toggleDrawerLeft() {
      this.drawer = !this.drawer;
    },
    toggleSheetCreateOrderWork() {
      if (this.role[0] != 'super-admin') {
        this.form_create_work.workshop_id = this.workshop.id;
        this.getWarehousesByLocation(this.form_create_work.workshop_id);
      }
      this.currentWarehouse = null;
      this.sheet_create_modal = !this.sheet_create_modal;
    },
    toggleDrawerCreateOrderWork() {
      this.drawer_create_order = !this.drawer_create_order;
    },
    toggleDrawerCreateOrderSupplies() {
      if (this.form_create_work.has_warehouse) {
        this.drawer_supplies_with_warehouse = !this.drawer_supplies_with_warehouse;
      } else {
        this.drawer_supplies = !this.drawer_supplies;
      }
    },
    toogleDrawerService(val) {
      this.drawer_service = !this.drawer_service;
    },
    toggleDrawerFilterHistoryOrderWork(from) {
      this.from_filter = from;
      this.drawer_filter_history_order_work = !this.drawer_filter_history_order_work;
    },
    openSnackbar(text) {
      this.snackbar.visible = true;
      this.snackbar.text = text;
    },
    async handleDrivers() {
      try {
        const response = await apiOtsService.getDrivers();
        this.drivers = response.data.map((e) => {
          return {
            ...e,
            fullText: e.names + ' ' + e.last_name_p + ' ' + e.last_name_m + ' ' + e.dni,
          };
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      // handle the response data here
    },
    countAttributesWithValues() {
      let count = 0;
      for (let key in this.filter_history_order_work) {
        if (this.role && this.role[0] != 'super-admin') {

          // if (this.filter_history_order_work.hasOwnProperty(key) && this
          //   .filter_history_order_work[key] !== '' &&filter_history_order_work[key] != null  && key !== 'location') {
          //   count++;
          // }
        } else {
          if (
            this.filter_history_order_work.hasOwnProperty(key) &&
            this.filter_history_order_work[key] !== ''
          ) {
            count++;
          }
        }
      }
      this.counterFilterHistory = count;
    },
    resetFilterHistoryOts() {

      this.filter_history_order_work = {
        from: '',
        to: '',
        truck: '',
        location: '',
        responsable: '',
        applicant: '', // "SOLICITANTE"
        specialists: '', // "ENCARGADO"
        review_by: '', // "REVISADO POR"
        delivered_to: '', // "ENTREGADO A"
        search: '',
      }

      this.countAttributesWithValues();
    },
    resetForm() {
      this.form_create_work = {
        services: [],
        coupled_id: '',
        workshop_id: '',
        has_warehouse: this.form_create_work.has_warehouse,
        workshop_selected_supply: false,
        applicant: '',
        id: '',
        signature_image: null,
        truck_id: '',
        applicant: '',
        responsible: '',
        kilometer: '',
        request: '',
        description_of_requeriment: '',
        path_image_signature: '',
        date_estimed: '',
        hour_estimed: '',
        place: '',
        type_of_maintenance: '',
        status: 'PENDING',
        codes: [],
        review_by: '',
        owner_user_id: '',
        applicant_driver_id: '',
        responsible_specialist_id: '',
        reviewed_by_specialist_id: '',
        delivered_to_driver_id: '',
        delivered_to: '',
        observation: '',
        works: [],
        deadline_at: '',
        defaultWork: {
          id: "",
          description: "",
          reviewed_by_specialist_id: "",
          hours: '',
          task: '',
          specialist: "",
          target_start_date: "",
          target_start_time: "",
          target_end_date: "",
          target_end_time: "",
          start_time: "",
          end_time: "",
          duration: "",
          duration_time: "",
          duration_hour: "",
        },
        editedIndexWork: -1,
        editedWork: {
          id: "",
          description: "",
          reviewed_by_specialist_id: "",
          hours: '',
          task: '',
          specialist: "",
          target_start_date: "",
          target_start_time: "",
          target_end_date: "",
          target_end_time: "",
          start_time: "",
          end_time: "",
          duration: "",
          duration_time: "",
          duration_hour: "",
        },
        supplies: [],
        supplies_with_warehouse: [],
        defaulSupplies: {
          id: '',
          code: '',
          name: '',
          description: '',
          amount: '',
          cost: null,
        },
        editedIndexSupplies: -1,
        editedSupplies: {
          id: '',
          code: '',
          name: '',
          description: '',
          amount: '',
          cost: null,
        },
        img_signature_delivered: '',
        img_signature_reviewed: '',
        is_signature_delivered: false,
        is_signature_reviewed: false,
        workshop_entry_date: '',
        workshop_entry_time: '',
        workshop_departure_date: '',
        workshop_departure_time: '',
      };
    },
    async handleShowOrderWork(id) {
      this.order_is_edited = true;

      let response = null;

      try {
        if (this.form_create_work.has_warehouse) {
          response = await apiOtsWarehouseService.getOtsById(id);
        } else {
          response = await apiOtsService.getOtsById(id);
        }


        this.form_create_work.has_warehouse = response.data.has_warehouse;

        this.form_create_work = { ...this.form_create_work, ...response.data };

        this.form_create_work.works = response.data.combinedWorks;

        this.form_create_work.services = response.data.work_order_service.map((el) => ({
          ...el,
          ...el.provider,
          provider_id: el.service.provider.id,
          provider_name: el.service.provider.name,
          code: el.service.code,
          name: el.service.name,
          description: el.service.description,
          category: el.service.category,
          bill: el.invoice,
          count: el.count,
          total_price: el.total_price
        }));

        this.form_create_work.has_warehouse = this.form_create_work.has_warehouse === 1 ? true : false

        this.form_create_work.works = response.data.combinedWorks.map((el) => {

          let start = moment(el.start_time);
          let end = moment(el.end_time);
          let duration = null;
          let target_hours = null;
          let minutes = null;
          let target_duration = null;

          if (el.hours && !el.start_time && !el.end_time) {

            duration = moment.duration(el.hours, 'hours');
            target_hours = Math.floor(duration.asHours());
            minutes = Math.floor(duration.minutes());
            target_duration = `${target_hours} hours ${minutes} minutes`;

          }

          if (el.start_time && !el.end_time) {
            target_duration = null;
          }

          if (el.start_time && el.end_time) {
            duration = moment.duration(end.diff(start));
            target_hours = Math.floor(duration.asHours());
            minutes = Math.floor(duration.minutes());
            target_duration = `${target_hours} hours ${minutes} minutes`;

          }

          return {
            ...el,
            target_start_date: el.start_time ? moment(el.start_time).format('YYYY-MM-DD') : '',
            target_start_time: el.start_time ? moment(el.start_time).format('HH:mm') : '',
            target_end_date: el.end_time ? moment(el.end_time).format('YYYY-MM-DD') : '',
            target_end_time: el.end_time ? moment(el.end_time).format('HH:mm') : '',
            start_time: el.start_time ? moment(el.start_time) : null,
            end_time: el.end_time ? moment(el.end_time) : null,
            duration: target_duration,
          }
        });

        if (this.form_create_work.has_warehouse) {
          this.form_create_work.supplies_with_warehouse = response.data.order_supplies.map(el => ({
            ...el,
            new_count: el.count
          }));
        } else {
          this.form_create_work.supplies = response.data.order_supplies;
        }


        this.supplies = this.form_create_work.supplies
          ? this.supplies.map((el) => {
            let index = this.form_create_work.supplies.find(
              (item) => item.supply_id == el.id
            );
            return { ...el, aggregate: index ? true : false };
          })
          : [];

        this.getWarehousesByLocation(this.form_create_work.workshop_id);

        this.handleSpecialists({ 'workshop': this.form_create_work.workshop_id });

      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    async fetchOtsCalendars(filters) {
      this.loadingCalendar = true;
      const response = await apiOtsService.getAllOtsCalendar(filters);
      this.otsCalendars = response.data.map((el) => {

        return {
          id: el.id,
          name: `#${el.id} - ${el.description_of_requeriment}`,
          start: el.workshop_entry_date && el.workshop_entry_time
            ? moment(`${el.workshop_entry_date} ${el.workshop_entry_time}`).format('YYYY-MM-DD HH:mm')
            : null,
          end: el.date_estimed && el.hour_estimed
            ? moment(`${el.date_estimed} ${el.hour_estimed}`).format('YYYY-MM-DD HH:mm')
            : null,
          start_format: el.workshop_entry_date && el.workshop_entry_time
            ? moment(`${el.workshop_entry_date} ${el.workshop_entry_time}`).format('HH:mm')
            : null,
          end_format: el.date_estimed && el.hour_estimed
            ? moment(`${el.date_estimed} ${el.hour_estimed}`).format('HH:mm')
            : null,
          type: 'ots',
          truck : `${el.truck.code} / ${el.truck.patent}`,
        };

      })

      if (this.selectedEventType.includes('Ordenes de Trabajo')) {
        this.events = this.otsCalendars;
      }
      if (this.selectedEventType.includes('Ordenes de Trabajo') && this.selectedEventType.includes('Trabajos')) {
        this.events = [...this.otsCalendars, ...this.worksCalendars];
      }
      this.loadingCalendar = false;
    },

    async fetchWorksCalendars(filters) {
      this.loadingCalendar = true;
      const response = await apiOtsService.getAllWorksCalendar(filters);

      // obten todos los responsables de los trabajos
      // let targetGroup =  response.data.map((el) => {
      //   return {
      //     id: el.specialist_id,
      //     name: el.specialist.names + ' ' + el.specialist.last_name_p + ' ' + el.specialist.last_name_m,
      //   }
      // } );

      // this.groupsCalendar = targetGroup.map((el) => el.name);

      let works = response.data.map((el) => {
        console.log(el);
        if (el && el.start_time) {
          let task = el.task != null ? el.task.name : '';
          return {
            id: el.id,
            ot : `N° OT - ${el.work_order.id}`,
            name: `${el.id} - ${task}`,
            start : el.start_time ? moment(el.start_time).format('YYYY-MM-DD HH:mm') : null,
            end : el.end_time ? moment(el.end_time).format('YYYY-MM-DD HH:mm') : null,
            specialist : el.specialist.names + ' ' + el.specialist.last_name_p + ' ' + el.specialist.last_name_m,
            color :'blue',
            // category: targetGroup.find((item) => item.id === el.specialist_id),
            type: 'works',
          }
        }
      }).filter(el => el !== undefined);

      this.worksCalendars = works;

      if (this.selectedEventType.includes('Trabajos')) {
        this.events = this.worksCalendars;
      }

      if (this.selectedEventType.includes('Ordenes de Trabajo') && this.selectedEventType.includes('Trabajos')) {
        this.events = [...this.otsCalendars, ...this.worksCalendars];
      }
      this.loadingCalendar = false;
    },
    async fetchBoxes() {
      const response = await apiOtsService.getBoxes();
      this.boxes = response.data;
    },
    createFakeFormOrderWork() {
      this.form_create_work = {
        has_warehouse: true,
        workshop_selected_supply: false,
        warehouse_id: '',
        workshop_id: 6,
        applicant: '',
        id: '',
        signature_image: null,
        codes: '26',
        truck_id: 207,
        responsible: '',
        kilometer: '4334',
        request: '',
        description_of_requeriment: 'test',
        path_image_signature: '',
        date_estimed: '2023-09-28',
        hour_estimed: '03:04',
        place: 'WORKSHOP',
        type_of_maintenance: 'PREVENTIVE',
        status: 'PENDING',
        review_by: '',
        owner_user_id: '',
        applicant_driver_id: 441,
        responsible_specialist_id: 21,
        reviewed_by_specialist_id: '',
        delivered_to_driver_id: '',
        delivered_to: '',
        observation: '',
        works: [],
        deadline_at: '',
        has_warehouse: true,
        defaultWork: {
          id: "",
          description: "",
          reviewed_by_specialist_id: "",
          hours: '',
          task: '',
          specialist: "",
          target_start_date: "",
          target_start_time: "",
          target_end_date: "",
          target_end_time: "",
          start_time: "",
          end_time: "",
          duration: "",
          duration_time: "",
          duration_hour: "",
        },
        editedIndexWork: -1,
        editedWork: {
          id: "",
          description: "",
          reviewed_by_specialist_id: "",
          hours: '',
          task: '',
          specialist: "",
          target_start_date: "",
          target_start_time: "",
          target_end_date: "",
          target_end_time: "",
          start_time: "",
          end_time: "",
          duration: "",
          duration_time: "",
          duration_hour: "",
        },
        supplies: [],
        supplies_with_warehouse: [],
        defaulSupplies: {
          id: '',
          code: '',
          name: '',
          description: '',
          amount: '',
          cost: null,
        },
        editedIndexSupplies: -1,
        editedSupplies: {
          id: '',
          code: '',
          name: '',
          description: '',
          amount: '',
          cost: null,
        },
        coupled_id: 44,
        workshop_entry_date: '',
        workshop_entry_time: '',
        workshop_departure_date: '',
        workshop_departure_time: '',
      };
    },
  },
});
